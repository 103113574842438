import * as React from 'react'
import { Outlet, RouteObject, useLocation } from 'react-router-dom'
import {
  LayoutProvider,
  Page,
  PageBody,
  Title
} from '@toasttab/buffet-pui-config-templates'
import { useUser } from '@toasttab/ec-session'
import { useQuery } from '@tanstack/react-query'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import {
  PausedPayPeriod,
  PayPeriodRequest
} from './models/pausedPayrollDetails'
import { PausedPayPeriodCard } from './PausedPayPeriodCard'
import { PausedPayPeriodConfirmationModal } from './PausedPayPeriodConfirmationModal'
import { useEffect, useState } from 'react'

export const FraudDashboardRoute: RouteObject = {
  path: 'payroll-ops/fraud-dashboard',
  element: <FraudDashboard />,
  children: []
}

function FraudDashboard() {
  const { isUserPayrollOps } = useUser()

  if (!isUserPayrollOps) {
    return (
      <div className='type-default'>hmm... you aren't suppose to be here</div>
    )
  }
  return <PayPeriodsList />
}

function PayPeriodsList() {
  const location = useLocation()
  let mostRecentScrollToHash = React.useRef<string>('')
  const [isConfirmationModalOpen, setConfirmationModalOpen] =
    useState<boolean>(false)
  const [request, setRequest] = useState<PayPeriodRequest | null>(null)
  const fetchPausedPayPeriods = async () => {
    const res = await fetch(`/paused-pay-periods/`)
    if (!res.ok) {
      throw new Error('HTTP error: ' + res.status)
    }
    return res.json()
  }

  useEffect(() => {
    if (location.hash) {
      let hash = location.hash.replace('#', '')
      const element = document.getElementById(hash)
      if (element && hash !== mostRecentScrollToHash.current) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
        mostRecentScrollToHash.current = hash
      }
    }
  })

  const {
    data,
    isFetching,
    refetch
  }: {
    data: undefined | null | PausedPayPeriod[]
    isFetching: boolean
    refetch: () => void
  } = useQuery({
    queryKey: ['pausedPayPeriods'],
    queryFn: fetchPausedPayPeriods
  })

  if (isFetching) {
    return <MerryGoRound />
  }

  return (
    <LayoutProvider disableMaxWidth>
      <Page className='mt-10'>
        <PageBody className='type-default'>
          <Title className='border-b mb-6 pb-5'>Payroll fraud</Title>
          <div className=' flex flex-col-reverse md:flex-row space-y-4 space-x-4'>
            {data?.length ? (
              <div className='space-y-5 flex-grow max-w-4xl'>
                {data
                  .sort((a, b) => {
                    return (
                      new Date(b.postedDateTime).getTime() -
                      new Date(a.postedDateTime).getTime()
                    )
                  })
                  .map((payPeriod) => (
                    <PausedPayPeriodCard
                      key={payPeriod.uuid}
                      payPeriod={payPeriod}
                      setRequest={(request: PayPeriodRequest) => {
                        setRequest(request)
                        setConfirmationModalOpen(true)
                      }}
                    />
                  ))}
                <PausedPayPeriodConfirmationModal
                  isModalOpen={isConfirmationModalOpen}
                  request={request}
                  handleModalClose={(reload: boolean) => {
                    setConfirmationModalOpen(false)
                    setRequest(null)
                    if (reload) {
                      refetch()
                    }
                  }}
                />
              </div>
            ) : (
              <div>There are currently no payrolls on pause.</div>
            )}
          </div>
          <Outlet />
        </PageBody>
      </Page>
    </LayoutProvider>
  )
}
