import * as React from 'react'
import { useMutation } from '@tanstack/react-query'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { Modal, ModalHeader } from '@toasttab/buffet-pui-modal'
import { PayPeriodRequest } from './models/pausedPayrollDetails'

export function PausedPayPeriodConfirmationModal({
  request,
  isModalOpen,
  handleModalClose
}: {
  request: PayPeriodRequest | null
  handleModalClose: (reload: boolean) => void
  isModalOpen: boolean
}) {
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()
  const { mutate: closeOrResumePayPeriod } = useMutation({
    mutationFn: async (action: 'close' | 'resume') => {
      const response = await fetch(`/paused-pay-periods/${action}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/com.toasttab.payroll.v1+json'
        },
        body: JSON.stringify({
          customerUuid: request?.payPeriod.customerUuid,
          payPeriodUuid: request?.payPeriod.uuid
        })
      })
      if (!response.ok) {
        throw new Error('HTTP error: ' + response.status)
      }
    },
    onSuccess: () => {
      handleModalClose(true)
      showSuccessSnackBar(
        request?.action === 'close'
          ? 'You closed this payroll.'
          : 'You resumed this payroll.'
      )
    },
    onError: () => {
      handleModalClose(false)
      showErrorSnackBar(
        request?.action === 'close'
          ? 'Error closing pay period.'
          : 'Error resuming pay period.'
      )
    }
  })

  const isCloseAction = request?.action === 'close'

  return (
    <>
      <Modal isOpen={!!request && isModalOpen}>
        <ModalHeader>
          {isCloseAction
            ? 'Are you sure you want to manually close this payroll period?'
            : 'Are you sure you want to resume sweep?'}
        </ModalHeader>
        <Modal.Body>
          <div className='space-y-3'>
            <div>
              This payroll period is considered fraud and will not be processed
              and distributed to employees. You cannot undo this action.
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='flex-grow sm:flex-none'
            variant='link'
            onClick={() => {
              handleModalClose(false)
            }}
          >
            Cancel
          </Button>
          <Button
            className='flex-grow sm:flex-none'
            onClick={() => {
              closeOrResumePayPeriod(request!.action)
            }}
          >
            {isCloseAction ? 'Manually close' : 'Resume sweeps'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
